<script>
import AuthService from "@/api/services/auth.service.js";
import appConfig from "@/app.config";
import i18n from "@/i18n";
import Layout from "@/router/layouts/auth";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";

// custom validator for datafile Data Record Start Row
const acceptedTermsValidation = (value) => {
  return value === true ? true: false ;
};

/**
 * Register Company
 */
export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      /**** Data variables ****/
      companyName: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      termsAccept: false,
      newsLetterAccept: false,
      urlPaths: {
        en: {
          termsAndConditions: "https://redem.io/wp-content/uploads/2024/11/ReDem_AGBs_2024_EN.pdf",
          privacyPolicy: "https://redem.io/wp-content/uploads/2024/11/ReDem_Datenschutzerklarung_2024_EN.pdf",
          dataProcessingAgreement: "https://redem.io/data-processing-agreement"
        },
        de: {
          termsAndConditions: "https://redem.io/wp-content/uploads/2024/11/ReDem_AGBs_2024_DE.pdf",
          privacyPolicy: "https://redem.io/wp-content/uploads/2024/11/ReDem_Datenschutzerklarung_2024_DE.pdf",
          dataProcessingAgreement: "https://redem.io/wp-content/uploads/2024/11/ReDem_Auftragsverarbeitungsvertrag_2024_EN.pdf"
        }
      },
      /**** Operational variables ****/
      registrationErrorMessage: null,
      isRegisterError: false,
      registerSuccess: false,
      submit: false,
      isDisplayExpandNewsLetter: false,
      isDisplayExpandTerms: false,
      /**** Supportive variables ****/
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
    };
  },
  validations: {
    companyName: { required },
    firstName: { required },
    lastName: { required },
    email: { required, email },
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAs: sameAs("password") },
    termsAccept: {acceptedTermsValidation}
  },
  methods: {
    clickRegSubmit() {
      this.submit = true;

      // stop here if form is invalid
      this.$v.$touch();

      if(this.$v.$invalid) {
        this.registrationErrorMessage = this.$t("registration.form.validationErrorMsg");
        this.isRegisterError = true;
      } else if (!this.$v.termsAccept.acceptedTermsValidation) {
        this.registrationErrorMessage = this.$t("registration.form.termsNotAcceptedErrorMsg");
        this.isRegisterError = true;
      } else {
        this.registerCompany();
      }
    },
    async registerCompany() {
      // Reset the registrationErrorMessage if it existed.
      this.registrationErrorMessage = null;

      const registerCompanyDTO = {
        companyName: this.companyName,
        companyOwnerFirstName: this.firstName,
        companyOwnerLastName: this.lastName,
        email: this.email.trim(),
        password: this.password,
        hasAcceptedTerms: this.termsAccept,
        hasAcceptNewsLetter: this.newsLetterAccept
      };

      try {
        await AuthService.registerCompany(registerCompanyDTO);
        this.registerSuccess = true;
        this.$router.push("./welcome");
      } catch (error) {
        this.isRegisterError = true;
        this.registrationErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
      
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    },
    showMoreTerms() {
      if (this.isDisplayExpandTerms) {
        this.isDisplayExpandTerms = false;
      } else {
        this.isDisplayExpandTerms = true;
      }
    },
    showMoreNewsLetter() {
      if (this.isDisplayExpandNewsLetter) {
        this.isDisplayExpandNewsLetter = false;
      } else {
        this.isDisplayExpandNewsLetter = true;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-8 col-xl-6">

        <!-- Registeration Form -->
        <div class="card overflow-hidden">
          <!-- Header -->
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">
                    {{ $t("registration.header.title") }}
                  </h5>
                  <p>{{ $t("registration.header.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-center">
                <img
                  src="@/assets/images/register-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>

          <!-- Form -->
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>

            <b-alert
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >{{ $t("registration.form.successMsg") }}</b-alert
            >

            <b-alert
              v-model="isRegisterError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ registrationErrorMessage }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="clickRegSubmit">

              <!-- Company Name -->
              <b-form-group id="company-group" label-for="companyName">
                <template v-slot:label>
                  {{ $t("registration.form.companyName.label") }}
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="companyName"
                  v-model="companyName"
                  type="text"
                  :placeholder="
                    this.$t('registration.form.companyName.placeholder')
                  "
                  :class="{ 'is-invalid': submit && $v.companyName.$error }"
                ></b-form-input>
                <div
                  v-if="submit && $v.companyName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.companyName.required">{{
                    $t("registration.form.companyName.requiredError")
                  }}</span>
                </div>
              </b-form-group>

              <!-- Company Owner Name -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="first-name-group" label-for="firstName">
                    <template v-slot:label>
                      {{ $t("registration.form.firstName.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="firstName"
                      v-model="firstName"
                      type="text"
                      :placeholder="
                        this.$t('registration.form.firstName.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.firstName.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.firstName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.firstName.required">{{
                        $t("registration.form.firstName.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group id="last-name-group" label-for="lastName">
                    <template v-slot:label>
                      {{ $t("registration.form.lastName.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="lastName"
                      v-model="lastName"
                      type="text"
                      :placeholder="
                        this.$t('registration.form.lastName.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.lastName.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.lastName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.lastName.required">{{
                        $t("registration.form.lastName.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <!-- Email -->
              <b-form-group id="email-group" label-for="email">
                <template v-slot:label>
                  {{ $t("registration.form.email.label") }}
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  :placeholder="this.$t('registration.form.email.placeholder')"
                  :class="{ 'is-invalid': submit && $v.email.$error }"
                ></b-form-input>
                <div v-if="submit && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">{{
                    $t("registration.form.email.requiredErrorMsg")
                  }}</span>
                  <span v-if="!$v.email.email">{{
                    $t("registration.form.email.notAnEmailErrorMsg")
                  }}</span>
                </div>
              </b-form-group>

              <!-- Password -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="password-group" label-for="password">
                    <template v-slot:label>
                      {{ $t("registration.form.password.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="password"
                      v-model="password"
                      type="password"
                      :placeholder="
                        this.$t('registration.form.password.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.password.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.password.required">{{
                        $t("registration.form.password.requiredErrorMsg")
                      }}</span>
                      <span v-if="!$v.password.minLength">{{
                        $t("registration.form.password.minLengthErrorMsg")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="password-group" label-for="confirmPassword">
                    <template v-slot:label>
                      {{ $t("registration.form.confirmPassword.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="confirmPassword"
                      v-model="confirmPassword"
                      type="password"
                      :placeholder="
                        this.$t('registration.form.confirmPassword.placeholder')
                      "
                      :class="{
                        'is-invalid': submit && $v.confirmPassword.$error
                      }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.confirmPassword.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.confirmPassword.required">{{
                        $t("registration.form.confirmPassword.requiredErrorMsg")
                      }}</span>
                      <span v-if="!$v.confirmPassword.sameAs">{{
                        $t("registration.form.confirmPassword.notMatchErrorMsg")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <!-- NewsLetter accept -->
              <div class="custom-control custom-checkbox mt-4">
                <input
                  id="customControlForNewsLetterInline"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="newsLetterAccept"
                />
                <label
                  class="custom-control-label"
                  for="customControlForNewsLetterInline"
                >
                  {{ $t("registration.form.newsLetter.text") }}
                  <i
                    class="bx bxs-info-circle ml-1"
                    @click="showMoreNewsLetter()"
                    style="cursor: pointer"
                  ></i>
                </label>

                <div>
                   {{$t("registration.form.newsLetter.supportText.text1")}} <b> {{$t("registration.form.newsLetter.supportText.text2")}} </b> {{$t("registration.form.newsLetter.supportText.text3")}} <b> {{$t("registration.form.newsLetter.supportText.text4")}}</b> {{$t("registration.form.newsLetter.supportText.text5")}}
                </div>
                
                <p v-if="isDisplayExpandNewsLetter" class="mt-3">
                  <small>
                    {{ $t("registration.form.newsLetter.expandText") }}
                    <a href="https://redem.io/privacy-policy" target="_blank"
                      >Privacy Policy</a
                    >
                  </small>
                </p>
              </div>

              <!-- Terms accept -->
              <div class="custom-control custom-checkbox mt-4">
                <input
                  id="customControlInline"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="termsAccept"
                  :class="{ 'is-invalid': submit && $v.termsAccept.$error }"
                />
                <label class="custom-control-label" for="customControlInline">
                  {{ $t("registration.form.terms.text1") }}
                  <a
                    :href="urlPaths[lan].termsAndConditions"
                    target="_blank"
                    >{{ $t("registration.form.terms.text2") }}</a
                  >
                  {{ $t("registration.form.terms.text3") }}
                  <a
                    :href="urlPaths[lan].dataProcessingAgreement"
                    target="_blank"
                    >{{ $t("registration.form.terms.text4") }}</a
                  >
                  {{ $t("registration.form.terms.text5") }}
                  <a
                    :href="urlPaths[lan].privacyPolicy"
                    target="_blank"
                    >{{ $t("registration.form.terms.text6") }}</a
                  >
                  {{ $t("registration.form.terms.text7") }}
                  <span class="text-danger">*</span>
                  <i
                    class="bx bxs-info-circle ml-1"
                    @click="showMoreTerms()"
                    style="cursor: pointer"
                  ></i>
                </label>
                <p v-if="isDisplayExpandTerms">
                  <small>
                    {{ $t("registration.form.terms.expandText") }}
                  </small>
                </p>
              </div>

              <!-- Button -->
              <div class="mt-4">
                <b-button type="submit" variant="success" class="btn-block">{{
                  $t("registration.form.registerButton")
                }}</b-button>
              </div>
            </b-form>
          </div>
        </div>

        <!-- Other Links -->
        <div class="mt-5 text-center">
          <p>
            {{ $t("registration.footer.text1") }}
            <router-link
              tag="a"
              to="/login"
              class="font-weight-medium text-info"
              >{{ $t("registration.footer.text2") }}</router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>

    </div>
  </Layout>
</template>
